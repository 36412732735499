import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import {
  fpjsPlugin,
  type FpjsVueOptions
  // FingerprintJSPro
} from '@fingerprintjs/fingerprintjs-pro-vue-v3';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  nuxtApp.vueApp.use(fpjsPlugin, {
    loadOptions: {
      apiKey: config.public.fingerprintKey,
      region: 'eu',
      endpoint: 'https://metrics.underdogz.com',
      scriptUrlPattern:
        'https://metrics.underdogz.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js'
    }
  } as FpjsVueOptions);
});
