<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog class="relative z-10" @close="closeDialog">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-x-0 bottom-0 flex max-h-full pt-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-y-full"
              enter-to="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-y-0"
              leave-to="translate-y-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-full">
                <div
                  class="flex relative h-full rounded-t-[8px] flex-col bg-neutral_500 min-h-[520px] max-h-[520px] shadow-xl"
                >
                  <div
                    v-if="props.hasComments"
                    class="overflow-y-scroll flex flex-col gap-[10px] p-3 mt-[48px] mb-[72px]"
                  >
                    <div v-for="(comment, index) in comments" :key="comment.id">
                      <Comment
                        :comment="comment"
                        @delete-comment="handleDeleteComment(comment, index)"
                        @show-replies="handleShowReplies"
                        @created-reply="handleReplyAdded"
                      />
                      <template v-if="comment.childComments?.length && comment.showReplies">
                        <div
                          v-for="(reply, indexOfReplies) in comment.childComments"
                          :key="`reply-${reply.id}`"
                          class="ml-6 mt-[10px]"
                        >
                          <Comment
                            :comment="reply"
                            @delete-comment="handleDeleteComment(reply, indexOfReplies)"
                          />
                        </div>
                      </template>
                    </div>
                    <ButtonComponent
                      v-if="nextCursor"
                      type="text"
                      text-color="text-base_white"
                      :text="$t('loadMoreComments')"
                      @click="loadMoreComments"
                    />
                  </div>
                  <div
                    v-else
                    class="h-full w-full mt-[48px] mb-[80px] flex items-center justify-center"
                  >
                    <div class="flex items-center flex-col gap-[10px]">
                      <img
                        src="../../assets/img/emptyBone.svg"
                        class="h-[32px]"
                        alt="picture of a bone"
                      />
                      <p
                        class="text-[22px] font-bold leading-[120%] tracking-[-0.44px] text-base_white"
                      >
                        {{ $t('throwTheBoneFirst') }}
                      </p>
                      <p
                        class="text-[18px] font-medium leading-[130%] text-base_white tracking-[-0.36px]"
                      >
                        {{ $t('noCommentsNow') }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="h-[80px] px-4 bg-neutral_500 flex items-center justify-center w-full absolute bottom-0 left-0"
                  >
                    <InputText
                      v-model="newCommentText"
                      helper-icon="send"
                      :placeholder-text="$t('comment')"
                      size="medium"
                      :display-error-text="false"
                      :error="$v.newCommentText.$error"
                      :error-messages="$v.newCommentText.$errors"
                      @helper-clicked="createNewComment"
                    />
                  </div>
                  <div
                    tabindex="1"
                    autofocus
                    class="h-[48px] outline-none cursor-pointer p-3 bg-neutral_500 flex items-center justify-center absolute top-0 left-0 w-full rounded-t-[8px]"
                    @click="closeDialog"
                  >
                    <p
                      class="text-[12px] text-base_white font-bold uppercase tracking-[1.2px] leading-[150%]"
                    >
                      {{ $t('close') }}
                    </p>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { postRepository } from '~/repositories/postRepository';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import type { Post, Comment } from '~/interfaces';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  },
  hasComments: {
    type: Boolean,
    required: true
  },
  post: {
    type: Object as PropType<Post>,
    required: true
  }
});
const emits = defineEmits(['comment-added', 'comment-deleted', 'update:isOpen']);
const { $api } = useNuxtApp();
const postRepo = postRepository($api);
const nextCursor = ref('');
const comments = ref<Comment[]>([]);
const newCommentText = ref('');
const rules = {
  newCommentText: {
    required
  }
};
const $v = useVuelidate(rules, { newCommentText });
watch(
  () => props.isOpen,
  async (newValue, oldValue) => {
    if (newValue && !oldValue && props.hasComments) {
      const response = await postRepo.getPostComments(props.post.id);
      comments.value = response.comments.map((comment: any) => ({
        ...comment,
        showReplies: false
      }));
      nextCursor.value = response.nextCursor;
    }
  }
);

const closeDialog = () => {
  emits('update:isOpen', false);
};

const handleShowReplies = (newValue: boolean, id: number) => {
  const commentToShowReplies = comments.value.find((p) => p.id == id);
  if (commentToShowReplies) {
    commentToShowReplies.showReplies = newValue;
  }
};
const handleDeleteComment = (comment: Comment, index: number) => {
  if (comment.parentCommentId) {
    const parentComment = comments.value.find((c) => c.id == comment.parentCommentId);
    parentComment?.childComments?.splice(index, 1);
  } else {
    comments.value.splice(index, 1);
  }
  emits('comment-deleted', comment);
};
const loadMoreComments = async () => {
  const response = await postRepo.getPostComments(props.post.id, nextCursor.value);
  const additionalComments = response.comments.map((comment: any) => ({
    ...comment,
    showReplies: false
  }));
  comments.value.push(...additionalComments);
  nextCursor.value = response.nextCursor;
};
const createNewComment = async () => {
  const isFormValid = await $v.value.$validate();
  if (isFormValid) {
    try {
      const newComment = await postRepo.createComment(newCommentText.value, props.post.id);
      newComment.showReplies = false;
      newCommentText.value = '';
      $v.value.$reset();
      emits('comment-added');
      comments.value.unshift(newComment);
    } catch (err) {
      closeDialog();
      console.log(err);
    }
  }
};
const handleReplyAdded = (newReply: Comment) => {
  emits('comment-added');
  const parentComment = comments.value.find((c) => c.id === newReply.parentCommentId);
  if (parentComment) {
    if (!parentComment.childComments) {
      parentComment.childComments = [];
    }
    parentComment.childComments.push(newReply);
  }
};
</script>
