<template>
  <div class="flex items-center gap-1 cursor-pointer" @click="isDrawerOpened = true">
    <ClientOnly>
      <ion-icon class="text-[24px] text-base_white" name="chatbubble-outline" />
    </ClientOnly>
    <p class="text-[12px] font-medium text-base_white">
      {{ numberOfComments }}
    </p>
  </div>
  <MobilePostCommentsDrawer
    v-model:is-open="isDrawerOpened"
    :has-comments="Boolean(numberOfComments)"
    :post="props.post"
    @comment-added="numberOfComments++"
    @comment-deleted="handleCommentDeleted"
  />
</template>
<script setup lang="ts">
import type { Comment, Post } from '~/interfaces';
const props = defineProps({
  numberOfComments: {
    type: Number,
    required: true
  },
  post: {
    type: Object as PropType<Post>,
    required: true
  }
});
const isDrawerOpened = ref(false);
const numberOfComments = ref(props.numberOfComments);
const handleCommentDeleted = (deletedComment: Comment) => {
  if (deletedComment.childComments) {
    numberOfComments.value = numberOfComments.value - deletedComment.childComments.length;
  }
  numberOfComments.value--;
};
</script>
