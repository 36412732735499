<template>
  <TransitionRoot as="template" :show="dialogStore.cookiesDialog.isOpen">
    <Dialog class="relative z-10" @close="closeCookiesModal">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral_950 bg-opacity-70 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-x-0 bottom-0 flex max-h-full pt-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-y-full"
              enter-to="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-y-0"
              leave-to="translate-y-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-full">
                <div class="flex relative h-full flex-col bg-neutral_600">
                  <div class="flex w-full items-center justify-center p-8 md:px-[128px] md:py-8">
                    <div class="flex flex-col gap-3 w-full md:max-w-[640px]">
                      <div class="flex items-center h-[40px] w-full justify-between">
                        <p
                          tabindex="2"
                          class="text-base_white text-[12px] outline-none uppercase font-bold tracking-[2.4px] leading-[150%]"
                        >
                          {{ $t('cookies') }}
                        </p>
                        <ClientOnly>
                          <ion-icon
                            class="text-[24px] cursor-pointer text-base_white"
                            name="close-outline"
                            @click="closeCookiesModal"
                          />
                        </ClientOnly>
                      </div>
                      <p
                        class="text-neutral_50 text-[14px] font-medium leading-[150%] tracking-[-0.28px]"
                      >
                        {{ $t('cookiesModalText1') }}
                        <NuxtLink class="text-primary_500" :to="localePath('/cookies-policy')">{{
                          $t('here')
                        }}</NuxtLink
                        >.<br /><br />
                        {{ $t('cookiesModalText2') }}
                      </p>
                      <div class="flex items-center gap-2 w-full">
                        <UToggle
                          v-model="necesseryCokies"
                          color="primary"
                          @click="handleUToggleClick"
                        />
                        <p
                          class="text-neutral_50 text-[14px] font-medium leading-[150%] tracking-[-0.28px]"
                        >
                          {{ $t('cookiesModalText3') }}
                        </p>
                      </div>
                      <div class="flex items-center gap-2 w-full">
                        <UToggle v-model="analyticsCookies" color="primary" />
                        <p
                          class="text-neutral_50 text-[14px] font-medium leading-[150%] tracking-[-0.28px]"
                        >
                          {{ $t('cookiesPolicyText8') }}
                        </p>
                      </div>
                      <p
                        class="text-neutral_50 text-[14px] font-medium leading-[150%] tracking-[-0.28px]"
                      >
                        {{ $t('cookiesModalText4') }}

                        <br /><br />
                        {{ $t('cookiesModalText5') }}

                        <NuxtLink class="text-primary_500" :to="localePath('/cookies-policy')">{{
                          $t('cookies')
                        }}</NuxtLink>
                        {{ $t('cookiesModalText6') }}
                      </p>
                      <div class="w-full h-px bg-primary_950" />
                      <ButtonComponent
                        :text="$t('saveAndClose')"
                        :cropped="true"
                        class="mx-auto"
                        @click="closeAndSaveSelection"
                      />
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useDialogStore } from '~/store/dialogStore';

const dialogStore = useDialogStore();
const localePath = useLocalePath();

const {
  value: storageAnalyticsCookies,
  saveValue: saveAnalyticsValue,
  loadValue: loadStorageAnalytics
} = useTmpLocalStorage<boolean>('allowedAnalyticsCookies');

const necesseryCokies = ref(true);
const analyticsCookies = ref(true);

onMounted(() => {
  loadStorageAnalytics();
  if (storageAnalyticsCookies.value !== null) {
    analyticsCookies.value = storageAnalyticsCookies.value;
  }
});

const handleUToggleClick = (e: Event) => {
  e.preventDefault(); // ovo je za obavezne kolacice, da ne bi mogli da se ugase
};
const closeCookiesModal = () => {
  dialogStore.setCookiesDialogState(false);
};

const closeAndSaveSelection = () => {
  dialogStore.setCookiesDialogState(false);
  localStorage.setItem('closedCookiesModal', 'true');
  saveAnalyticsValue(analyticsCookies.value);
};
</script>
