<template>
  <!-- <div
    v-if="!props.formElement"
    class="cursor-pointer min-w-[72px]"
    :class="[
      typeClasses[props.type]['div'],
      props.streched ? 'w-full flex items-center justify-center' : '',
      props.cropped ? 'w-max' : '',
      sizeClasses[props.size]['div']
    ]"
  >
    <p
      class="px-1 font-bold leading-[150%] flex items-center justify-center"
      :class="[
        typeClasses[props.type]['p'],
        sizeClasses[props.size]['p'],
        props.cropped ? 'inline-block' : ''
      ]"
    >
      <slot>{{ props.text }}</slot>
    </p>
  </div> -->
  <button
    :type="props.formElement ? 'submit' : 'button'"
    :disabled="props.disabled || props.isLoading"
    class="min-w-[72px]"
    :class="[
      typeClasses[props.type]['div'],
      props.streched ? 'w-full flex items-center justify-center' : '',
      props.cropped ? 'w-max' : '',
      props.disabled || props.isLoading ? 'opacity-50 cursor-not-allowed' : '',
      sizeClasses[props.size]['div'],
      props.isLoading ? 'flex gap-1' : ''
    ]"
  >
    <p
      class="leading-[150%] text-center flex items-center justify-center"
      :class="[
        props.fontThickness,
        typeClasses[props.type]['p'],
        sizeClasses[props.size]['p'],
        props.cropped ? 'inline-block' : ''
      ]"
    >
      <slot>{{ props.text }}</slot>
    </p>
    <ion-icon
      v-if="props.isLoading"
      class="text-[24px] animate-spin"
      :class="props.type == 'filled' ? 'text-primary_900' : 'text-primary_500'"
      name="refresh-circle"
    />
  </button>
</template>
<script setup lang="ts">
type ButtonType = 'filled' | 'outlined' | 'text';
type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
const props = defineProps({
  type: {
    type: String as PropType<ButtonType>,
    default: 'filled'
  },
  size: {
    type: String as PropType<ButtonSize>,
    default: 'sm'
  },
  //ovo moze da radi sa cropped da bi centriralo sadrzaj
  streched: {
    type: Boolean,
    default: false
  },
  text: {
    type: String,
    default: '',
    required: false
  },
  //kada se rasiri nekad zbog roditeljskog div-a ovo mu treba da bude sirine kao content
  cropped: {
    type: Boolean,
    default: false
  },
  formElement: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  textColor: {
    type: String,
    default: 'text-primary_500'
  },
  textSize: {
    type: String,
    required: false
  },
  fontThickness: {
    type: String,
    default: 'font-bold'
  }
});
const typeClasses = {
  filled: {
    div: 'bg-primary_500 rounded-full',
    p: 'text-primary_900'
  },
  outlined: {
    div: 'bg-neutral_950 rounded-full border-[2px] border-primary_700 box-border',
    p: 'text-primary_500'
  },
  text: {
    div: '',
    p: props.textColor
  }
};
const sizeClasses = {
  xs: {
    p: 'text-[12px]',
    div: 'px-3 py-[4px]'
  },
  sm: {
    p: `${props.textSize ? props.textSize : 'text-[14px]'} tracking-[-0.28px]`,
    div: 'px-3 py-[6px]'
  },
  md: {
    p: 'text-base tracking-[-0.32px]',
    div: 'px-3 py-2'
  },
  lg: {
    p: 'text-[20px]',
    div: 'px-4 py-[9px]'
  },
  xl: {
    p: 'text-[22px]',
    div: 'px-5 py-4'
  }
};
</script>
