<template>
  <NuxtLayout>
    <SocketConnection />
    <NuxtPage />
    <ConfirmationDialog />
    <PostDialog v-if="isDialogStoreReady && dialogStore.postDialog.isOpen" />
    <LoginDialog v-if="isDialogStoreReady && dialogStore.loginDialog.isOpen" />
    <CookiesModal v-if="isDialogStoreReady && dialogStore.cookiesDialog.isOpen" />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useDialogStore } from '~/store/dialogStore';
import { useCommonStore } from '~/store/commonStore';
const dialogStore = useDialogStore();
const commonStore = useCommonStore();
const isDialogStoreReady = ref(false);

const updateScreenWidth = () => {
  commonStore.setCurrentWidth(window.innerWidth);
};

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `Underdogz - ${titleChunk}` : 'Underdogz';
  },
  script: [
    {
      innerHTML: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '430920450033447');
        fbq('track', 'PageView');
      `,
      type: 'text/javascript'
    }
  ],
  noscript: [
    {
      children: `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=430920450033447&ev=PageView&noscript=1" />`
    }
  ]
});

onMounted(() => {
  if (import.meta.client) {
    updateScreenWidth();
    window.addEventListener('resize', updateScreenWidth);

    nextTick(() => {
      isDialogStoreReady.value = true;
      dialogStore.loadCookiesDialogStateFromStorage();
    });
  }
});
</script>
